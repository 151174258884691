import { Component, Inject, OnInit } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {

  constructor(
    private route: Router,
  ) { }

  ngOnInit(): void {
  }
  goToHome(): void {
    this.route.navigate(['/home']); 
  }

  tryAgain(): void {
    this.route.navigate(['/checkout']); 
  }
}
