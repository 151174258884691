import {  Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartService } from '../api/cart.service';
import { OrderService } from '../api/order.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { PaymentResponseComponent } from '../payment-response/payment-response.component';

declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  form1: FormGroup;
  responseOrder;
  paymentResult;
  somethingWrong = false;
  processing = true;
  cartItems: any = [];
  constructor(
    private location: Location,
    private cartService: CartService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private zone: NgZone,
    private spinner: NgxSpinnerService,
  ) { 

    this.createForm1();
  }

  ngOnInit(): void {
   
    this.spinner.show('spinner1');
    this.cartService.getCart().subscribe((resp) => {
      this.processing = false;
      if (resp && resp.product.length > 0) {
        this.cartItems = resp;
        this.calculateTotalPrice();
        this.spinner.hide('spinner1');
      } else {
        this.cartItems = [];
        this.spinner.hide('spinner1');
      }
    });
  }

  goBack(): void {
    this.location.back();
  }
  calculateTotalPrice(): void {
    const deliveryCharge = 41.50;
    this.cartItems.DeliveryCharge = deliveryCharge
    this.cartItems.AmountPaid = this.cartItems.subTotal +deliveryCharge;
  }

  addingCart(index: number): void {
    const limit = this.cartItems.product[index].product.maximumOrderAllow;
    const { product,  quantity } = this.cartItems.product[index];

  

    this.cartService.addItemToCart(product);
  }

  onRemoveFromCart(index: number): void {
    const { product } = this.cartItems.product[index];
    this.cartService.removeItemFromCart(product);
   
  }
  

  createForm1() {
    this.form1 = this.fb.group({
      address: ['', Validators.required],
      building: ['', Validators.required],
      landmark: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', Validators.compose([Validators.required,Validators.minLength(6),Validators.maxLength(6)])],
      name: [null,Validators.compose([ Validators.required,Validators.minLength(3),Validators.maxLength(25),Validators.pattern('^([a-zA-Z]+\\s)*[A-Za-z\\s]+$')])],
      email:  [null,[Validators.required, Validators.email]],
      phone: [null,Validators.compose([Validators.required,Validators.minLength(10),Validators.maxLength(10)])],
      
    });
  }

  onSubmitup(value){
    this.spinner.show('spinner1');
    var temp = {
       "AmountPaid": parseFloat(this.cartItems.AmountPaid),
       "DeliveryCharge": this.cartItems.DeliveryCharge,
        "subTotal": this.cartItems.subTotal,
        "product": this.cartItems.product,
      
        "address": {
          "address": value.address,
          "building": value.building,
          "landmark": value.landmark,
          "city": value.city,
          "state": value.state,
          "pincode": value.pincode,
          "name": value.name,
          "email": value.email,
          "phone": value.phone

        }
    }

    this.orderService.addorder(temp).subscribe((resp) => {
      console.log("responce for serev",resp);
      this.responseOrder = resp;
      this.spinner.hide('spinner1');
      var redirecturl = "https://pay.easebuzz.in/pay/"+this.responseOrder.data.data;
      // var redirecturl = "https://testpay.easebuzz.in/pay/"+this.responseOrder.data.data;
      console.log(redirecturl);
      window.open(redirecturl, "_self")
      // this.onPayment(resp);
    }, error => {
      this.spinner.hide('spinner1');
      if (error.status === 400) {
        // this.snackService.openSnackBar(`${error.error.product} ${error.error.length > 0 ? 'are' : 'is'} out of stock`, 'warning');
        return;
      }
      // this.snackService.openSnackBar('We are now offline, try another store', 'error')
    });



    // console.log(value);
    // console.log(this.cartItems)
    // console.log(temp);
  }


  handle(paymentResponse: string): void {
    this.paymentResult = paymentResponse;
    this.openPaymentResponse();
    if (paymentResponse === 'pending') {
      this.orderService.paidPendingUpdate(this.responseOrder._id).subscribe();
    } else {
      this.orderService.paidSuccessUpdate(this.responseOrder._id).subscribe();
    }
  }

  onPaymentFail(): void {
    this.paymentResult = 'failed';
    this.openPaymentResponse();
  }

  onPayment(createdOrder): void {
    
    console.log(createdOrder);
    var options = {
      "key": "rzp_test_ZnDcPkwThpQRyw", // Enter the Key ID generated from the Dashboard
      "amount": createdOrder.AmountPaid, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "KapSar Industries", //your business name
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      "order_id": createdOrder.razorOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          "name": createdOrder.address.name, //your customer's name
          "email": createdOrder.address.email,
          "contact": createdOrder.address.phone //Provide the customer's phone number for better conversion rates 
      },
      handler: (response) => {
        console.log(response);
        if (response.razorpay_payment_id) {
          this.handle('success');
        } else {
          this.onPaymentFail();
        }
      },
      "notes": createdOrder.address
         
      ,
      "theme": {
          "color": "#3399cc"
      }
  };
  var rzp1 = new Razorpay(options);
  rzp1.open();

  var successCallback = function(success) {
    console.log("success call back",success);
    alert('payment_id: ' + success.razorpay_payment_id)
    var orderId = success.razorpay_order_id
    var signature = success.razorpay_signature
  };
  
  var cancelCallback = function(error) {
    console.log(error);
    alert(error.description + ' (Error '+error.code+')')
    alert(error.source);
          alert(error.step);
          alert(error.reason);
          alert(error.metadata.order_id);
          alert(error.metadata.payment_id);
  };

  }

  openPaymentResponse(): void {
    console.log("payemnt result",this.paymentResult);
    this.zone.run(() => {
      const dialogRef = this.dialog.open(PaymentResponseComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: { result: this.paymentResult }
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response === 'home') {
          this.router.navigateByUrl('/');
          localStorage.removeItem('cart');
          this.cartService.currentCart.next(null);
        }
      });
    });
  }

}
