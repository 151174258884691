import { Component, Input, OnDestroy, OnInit , ElementRef, ViewChild } from '@angular/core';
import { CartService } from 'src/app/api/cart.service';
import { MatDialog } from '@angular/material/dialog';

import { ProductDetailsComponent } from '../product-details/product-details.component';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { SwiperModule } from 'swiper/angular';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  currentSlide: number = 0;
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null;
  
  @Input() color = 'primary';
  @Input() productInfo: any;
  cart;
  product;
  counter = 0;
  selectedCustomization;
  screenSub: Subscription;
  showQuantityCounter = false;
  cartSubscription: Subscription;
  isHovering = false;
  isHovered: boolean;
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private cartService: CartService,
    
    
  ) { }
  config = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  ngOnInit(): void {

    // console.log(this.productInfo);
    if (this.productInfo) {
      this.product = this.productInfo;
      this.cartSubscription = this.cartService.getCart().subscribe((cart) => {
        this.cart = cart;
        if (this.cart && this.cart.product) {
          const cartItem = this.cart.product.filter(item => item.product._id === this.product._id);
          this.counter = 0;
          if (cartItem.length > 0) {
            this.showQuantityCounter = true;
            for (const item of cartItem) {
              this.counter += item.quantity;
            }
            return;
          }
          this.showQuantityCounter = false;
        } else {
          this.showQuantityCounter = false;
        }
      });
    }
   
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    })
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )
  }
  

  onAddToCart(): void {
    
      this.addingCart();
    
  }





  

  addingCart(): void {
    
      this.cartService.addItemToCart(
        this.product,
        this.counter
      );
    
  }

 

  ngOnDestroy(): void {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
    if (this.slider) this.slider.destroy();
  }
  onRemoveFromCart(): void {
    const items = this.cart.product.filter(item => item.product._id === this.product._id);
    if (items.length > 1) {
      this.cartService.removeItemFromCart(this.product, this.selectedCustomization);
      return;
    }
    this.cartService.removeItemFromCart(this.product);
  }

  openProductDetails(index): void {
    const dialogRef = this.dialog.open(ProductDetailsComponent, {
       maxHeight: '70vh',
       maxWidth: '70vw',
       minWidth: '50vw',
       minHeight: '50vh',
      // height: '1000px',
      // width: '1000px',
      
      data: { product: this.productInfo , ind: index},
      panelClass: 'product-modal'
    });

}
}
