import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RefundComponent } from './refund/refund.component';
import { TermsComponent } from './terms/terms.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShippingComponent } from './shipping/shipping.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { FailedComponent } from './failed/failed.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'refund', component: RefundComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'ship', component: ShippingComponent },
  { path: 'success', component: PaymentResponseComponent },
  { path: 'failed', component: FailedComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
