import { Component, Inject, OnInit , ElementRef, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { SwiperModule } from 'swiper/angular';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  currentSlide: number = 0;
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null;
  product;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log("this is getting from data",this.data);
    if (this.data.product) {
      this.product = this.data.product;
      this.currentSlide = this.data.ind;
    }

}
ngAfterViewInit() {
  setTimeout(() => {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel
      },
    })
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ]
  })
 
}

}
