import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from '../api/cart.service';
@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent {

  constructor(
    private route: Router,
    private cartService: CartService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialogRef: MatDialogRef<PaymentResponseComponent>
  ) { 
    
    localStorage.removeItem('cart');
    this.cartService.currentCart.next(null);


  }

  goToHome(): void {
    this.route.navigate(['/home']); 
  }

  tryAgain(): void {
    // this.dialogRef.close('try again');
  }

}
