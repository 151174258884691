import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cartcounter',
  templateUrl: './cartcounter.component.html',
  styleUrls: ['./cartcounter.component.scss']
})
export class CartcounterComponent {
  @Input() quantity = 0;
  @Input() color = 'primary';
  @Input() size = 'md';

  @Output() decrement = new EventEmitter();
  @Output() increment = new EventEmitter();

  onDecrement(): void {
    this.decrement.emit(this.quantity);
  }

  onIncrement(): void {
    this.increment.emit(this.quantity);
  }

}
