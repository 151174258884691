import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { timeInterval } from 'rxjs/operators';
import { CartService } from 'src/app/api/cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() toggle = new EventEmitter();
  
  cart;
  itemLength = 0;
  constructor(
    private router: Router,
    private cartService: CartService
  ) {

 
   }

  ngOnInit(): void {
    this.cartService.getCart().subscribe((items) => {
      if (items && items.product.length > 0) {
        this.itemLength = 0;
        items.product.map(item => this.itemLength += item.quantity);
      } else {
        this.itemLength = 0;
      }
    });
  }
  redirecttowhatsapp(){
    window.open('https://wa.me/917019331354', '_blank');
  }

  openCart(): void {
    console.log(this.cartService.currentCart.getValue());
    if (this.cartService.currentCart.getValue() && this.cartService.currentCart.getValue().product.length === 0) {
      alert('Nothing in cart');
      return;
    }else{
      this.router.navigate(['/checkout']);
    }

    
  } 


 

  
 


}
