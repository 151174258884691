import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  reharry(val){
    console.log(val);
    if(val == 'youtube'){
      window.open('https://www.youtube.com/@KapSarIndustries','_blank');
    }else if(val == 'instagram'){ 
      window.open('https://www.instagram.com/kapsarindustries/','_blank');
    }else{
      window.open('https://www.instagram.com/kapsarindustries/','_blank');
    }
  }

}
