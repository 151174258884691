import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  url: string;
  // private products = new BehaviorSubject([]);
  constructor(
    private http: HttpClient
  ) {
    // this.url = 'http://localhost:4009';
     this.url = 'https://kapsar.in:4009';
  }

  getProducts() {
    return this.http.get(`${this.url}/product/product`).pipe(map((products: any[]) => {
      // this.products.next(products);
      return products;
    }));
  }
  updateOrder(id) {
    return this.http.put(`${this.url}/order/paid/${id}`,"").pipe(map((item: any) => {
    
      return item;
    }));
  }
  addorder(val) {
    return this.http.post(`${this.url}/order/order`, val).pipe(map((item) => {
    
      return item;
    }));
  }
  paidSuccessUpdate(id) {
    return this.http.put(`${this.url}/order/paid/${id}`, {});
  }

  paidPendingUpdate(id) {
    return this.http.put(`${this.url}/order/pending/${id}`, {});
  }
  
}
