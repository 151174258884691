import { Component, OnDestroy, OnInit, NgZone, ElementRef, ViewChild   } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { OrderService } from 'src/app/api/order.service';
import { CartService } from 'src/app/api/cart.service';
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { KeenSliderInstance } from "keen-slider"



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  slider: KeenSliderInstance = null

  products = [];

  cartSubscription: Subscription;
  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private ngZone: NgZone
  ) { 
    this.getProducts();
  }

  ngOnInit(): void {
   
    
   
  }
 
  ngAfterViewInit() {
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 5000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }
  
  
  getProducts(): void {
    this.spinner.show('spinner1');
    this.orderService.getProducts().subscribe((response: any[]) => {
      this.products = response;
      // console.log(this.products);
      this.spinner.hide('spinner1');

    });
  }

  redirecttowhatsapp(){
    window.open('https://wa.me/917019331354', '_blank');
  }

  amazonredirecct(){
    window.open('https://www.amazon.in/dp/B0CKRP7MY6?ref_=cm_sw_r_apin_dp_CMMTNEQX58PX4PT5AQKD&language=en-IN', '_blank');
  }
flipkartredirect(){
  window.open('https://www.flipkart.com/kapsar-industries-glow-flow-water-diya-4-led-sensor-plastic-pack-6-table-set/p/itm581ce9edb999b?pid=DIYGUFFMYF5NHDNX', '_blank');
}
  addingCart(): void {
    
      
    
  }
  playVideo(){
    let image = document.querySelector('.video-container img') as HTMLElement;
    let video = document.getElementById('bigyoutube1') as HTMLVideoElement;
    let video1 = document.getElementById('bigyoutube1id') as HTMLVideoElement;
    
    let centeredSvg = document.querySelector('.centered-svg') as HTMLVideoElement;
    console.log(video);
    video1.style.height = image.clientHeight + 'px';
    // Hide the image
    image.style.display = 'none';
    centeredSvg.style.display = 'none';
    // Play the video
    video.style.display = 'block'; // Display the video element
    
   
  
    
  }

  playVideo2(){
    let image = document.querySelector('.video-container2 img') as HTMLElement;
    let video = document.getElementById('bigyoutube2') as HTMLVideoElement;
    let video1 = document.getElementById('bigyoutube1id2') as HTMLVideoElement;
    
    let centeredSvg = document.querySelector('.centered-svg2') as HTMLVideoElement;
    console.log(video);
    video1.style.height = image.clientHeight + 'px';
    // Hide the image
    image.style.display = 'none';
    centeredSvg.style.display = 'none';
    // Play the video
    video.style.display = 'block'; // Display the video element
  }
  playVideo3(){
    let image = document.querySelector('.video-container3 img') as HTMLElement;
    let video = document.getElementById('bigyoutube3') as HTMLVideoElement;
    let video1 = document.getElementById('bigyoutube1id3') as HTMLVideoElement;
    
    let centeredSvg = document.querySelector('.centered-svg3') as HTMLVideoElement;
    console.log(video);
    video1.style.height = image.clientHeight + 'px';
    // Hide the image
    image.style.display = 'none';
    centeredSvg.style.display = 'none';
    // Play the video
    video.style.display = 'block'; // Display the video element
  }
  playVideo4(){
    let image = document.querySelector('.video-container4 img') as HTMLElement;
    let video = document.getElementById('bigyoutube4') as HTMLVideoElement;
    let video1 = document.getElementById('bigyoutube1id4') as HTMLVideoElement;
    
    let centeredSvg = document.querySelector('.centered-svg4') as HTMLVideoElement;
    console.log(video);
    video1.style.height = image.clientHeight + 'px';
    // Hide the image
    image.style.display = 'none';
    centeredSvg.style.display = 'none';
    // Play the video
    video.style.display = 'block'; // Display the video element
  }

 


}
