import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  currentCart = new BehaviorSubject<any>(null);
  url;
  bottomSheetRef;
  itemCount = 0;
  private cartItems: any[] = [];
  constructor(
    private http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public zone: NgZone,
  ) {
    this.url = 'https://kapsar.in:4009';
    // this.url = `http://localhost:4009/order/`;
    const cart = this.getLocalStorage();
    if (cart && cart.product.length > 0) {
      this.setCart(cart);
    }
   }
  

  getCartItems(): any[] {
    return this.cartItems;
  }
   getCart() {
    return this.currentCart.asObservable();
  }

  setCart(cart) {
    this.currentCart.next(cart);
  }

  addItemToCart(product,  quantity?): void {
    if (quantity === 0) {
      quantity = 1;
    }
    console.log(quantity);
    let temp = this.currentCart.getValue();
    const cartItem = {
      product,
      quantity: quantity ? quantity : 1, price: product.price,
      totalPrice: (product.price * quantity)
    };
    console.log(cartItem);

    if (temp && temp.product && temp.product.length > 0) {
      const index = temp.product.findIndex(item => item.product.name === product.name );
      if (index < 0) {
        temp.product.push(cartItem);
      } else {
        temp.product[index].quantity += 1;
        temp.product[index].totalPrice =
          (+temp.product[index].price * temp.product[index].quantity) ;
      }
      this.calCulateSubTotal(temp);
    } else {
      temp = { product: [cartItem] };
      this.calCulateSubTotal(temp);
    }
    this.itemCount++;
  }

  calculateProductTotalPrice(price, quantity) {
    return (price * quantity);
  }

  removeItemFromCart(product, customiztion?): void {
    const temp = this.currentCart.getValue();
    const items = temp.product.filter(item => item.product.name === product.name);
    const index = temp.product.findIndex(item => item.product.name === product.name);

    if (items[0].quantity > 1) {
      items[0].quantity -= 1;
      items[0].totalPrice = this.calculateProductTotalPrice(items[0].price, items[0].quantity)
      temp.product[index] = items[0];
    } else {
      const updatedArray = temp.product.filter(item => item.product.name !== product.name);
      temp.product = updatedArray;
    }
    if (temp.product.length === 0) {
      this.clearLocalStorage();
      // this.closeCartSheet();
      this.bottomSheetRef = undefined;
    }
    this.calCulateSubTotal(temp);
    this.itemCount--;
  }
  
  calCulateSubTotal(cart): void {
    // let subTotal = 0;
    // for (const product of cart.product) {
    //   subTotal += +product.totalPrice;
    // }

    cart.subTotal = cart.product.reduce((acc, product) => {
      return acc + product.totalPrice;
    }, 0)

    // cart.subTotal = subTotal;
    this.currentCart.next(cart);

    if (cart.product.length > 0) { // this could be if(cart.product.length)
      this.storeInlocal(cart);
    }
  }

  storeInlocal(cart): void {
    localStorage.setItem('cart', JSON.stringify(this.currentCart.getValue()));
  }

  getLocalStorage() {
    return JSON.parse(localStorage.getItem('cart'));
  }

  clearLocalStorage() {
    localStorage.removeItem('cart');
  }
  clearCart(): void {
    this.clearLocalStorage();
    this.currentCart.next(null);
  }

  removeMulti(product): void {
    const temp = this.currentCart.getValue();
    const items = temp.product.filter(item => item.product.name === product.name );
    const index = temp.product.findIndex(item => item.product.name === product.name );

    if (items[0].quantity > 1) {
      items[0].quantity -= 1;
      items[0].totalPrice = this.calculateProductTotalPrice(items[0].price, items[0].quantity)
      temp.product[index] = items[0];
    } else {
      const updatedArray = temp.product.filter(item => item.product.name !== product.name ||
        (item.product.name === product.name));
      temp.product = updatedArray;
    }
    if (temp.product.length === 0) {
      this.clearLocalStorage();
    }
    this.calCulateSubTotal(temp);
    this.itemCount--;
  }




}
