import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SwiperModule } from 'swiper/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountdownModule } from 'ngx-countdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './app.material.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { RefundComponent } from './refund/refund.component';
import { TermsComponent } from './terms/terms.component';
import { CartcounterComponent } from './Product/cartcounter/cartcounter.component';
import { ProductCardComponent } from './Product/product-card/product-card.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ProductDetailsComponent } from './Product/product-details/product-details.component';
import { FailedComponent } from './failed/failed.component';





@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    PrivacyComponent,
    RefundComponent,
    TermsComponent,
    CartcounterComponent,
    ProductCardComponent,
    HomeComponent,
    PaymentResponseComponent,
    CheckoutComponent,
    ShippingComponent,
    ProductDetailsComponent,
    FailedComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    AppRoutingModule,
    HttpClientModule,
    CountdownModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
